import "../css/style.scss"

// Our modules / classes
// import MobileMenu from "./modules/MobileMenu"

// Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu()

import ImagesLoaded from "imagesloaded"
import Lazyload from "lazyload"

// Our modules / classes
// import Search from "./modules/Search"

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let titleHeight = 0
let navHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 800
let autoplayDelay = 3000
let orientationLandscape = true
let shuffleInterval
let startAutoShuffleTimeout

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}

let currentContentID = -1
let navOpen = true // initially open
let navChanging = false

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing

jQuery(function ($) {
  // if (debug) console.log("document ready!")

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------
  const openClose = $(".site-header__openclose")
  const navContainer = $(".site-header")

  const navMenuWrapper = $(".nav-menu-wrapper")
  const siteTitle = $("#site-title")
  const siteTitleLink = siteTitle.children("a")
  const logo = $(".custom-logo-container")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const menuNavSubLinks = $(".sub-menu > li.menu-item > a")
  const imprintTitle = $(".imprint-title")

  const contentContainer = $(".content-container")

  let $viewSelectorItems // = $('.view-selector__item');
  let $filterSelectorItems //  = $('.filter-selector__item'); // top level cats -> people, places, etc
  let $keywordsItems // = $('.keywords__item');
  let $projectsListingItems // = $('.projects-listing__project-item');
  let $keywordsContainer // = $('.keywords-container'); // contains the top level cats for filtering and the child cats as keywords
  let $picturesContainer // = $('.pictures-container'); // contains all images from the posts featured image
  let $projectsListingContainer // = $('.projects-listing'); // the list of project titles

  const pictureItems = $(".picture-item")

  const footer = $("footer.site-footer")

  let orangePictureIDsShown = []
  let bluePictureIDsShown = []

  // -----------------------------------------------------------------------------------------------------
  const $html = $("html")
  const $body = $("body")
  const $mainMenu = $(".menu-main")

  $html.addClass("js") // js is working

  setLandscape()
  touchSupport()

  // set highlight color from customizer
  document.documentElement.style.setProperty("--highlight-color", $("body").data("highlightcolor"))
  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  navAndTitleHeight()
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  navContainer.hide()
  navContainer.css("visibility", "visible")
  navContainer.fadeIn(400)

  contentContainer.hide()
  contentContainer.css("visibility", "visible")
  contentContainer.fadeIn()

  footer.css("visibility", "visible")

  setTimeout(() => {
    // safety
    navAndTitleHeight()
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  postItemContainers.hide()

  // content retrival and display -----------------------------------------------------------------------------------

  // content retrival and display end -------------------------------------------------------------------------------

  // menu navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    // menuNavLinks.on('click', function (e) {

    // only if it is a page menu item
    if ($(this).parent().hasClass("menu-item-object-page")) {
      e.preventDefault()
      // if (mobileView) closeNav(true);

      const slug = $(this).attr("href").split(`${window.location.origin}/`)[1]

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).text() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }
      }
      openPageLink($(this))
    }
  })

  function openPageLink($link) {
    console.log("open page link", $link.attr("href"))

    const parent = $link.parent()

    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = parent.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })

    if (pageID) {
      // hide all other page and post items
      if (pageID !== currentContentID) $(".post-item-container").not(`#${pageID}`).hide()

      currentContentID = pageID

      $("li.menu-item").removeClass("current-menu-item")
      parent.addClass("current-menu-item")

      // get permalink of the translated page and update the language switcher link
      $.getJSON(`${localized.root_url}/wp-json/translatedpostid/v1/get?id=${pageID}&lang=${contentContainer.data("alt-language")}`, (post) => {
        // console.log(post);
        // update the language switcher link
        $("li.lang-item > a").attr("href", post.href)
      })

      if ($(`div#${pageID}`).length == 0) {
        // page container does not exist yet -> load page with page id

        if (mobileView) closeNav(true)

        $.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, (page) => {
          console.log(page)

          postsContainer.append(`
                                <div id="${pageID}" class="post-item-container post-type-page initialized" data-title="${page.title.rendered}" data-history="${page.slug}">

                                    <div class="post-item-title">                                    
                                        <div class="post-item-title__title">${page.title.rendered}</div>
                                    </div>

                                    <div class="post-item-content">    
                                        ${page.content.rendered}
                                    </div>

                                </div>
                            `)

          const newPage = postsContainer.children(`#${pageID}`)
          newPage.hide()

          // home page is loaded by php anyways
          newPage.imagesLoaded(function () {
            addLazyloadToElement(newPage)
            newPage.fadeIn()
            scrollTop()
          })
        })
      } else {
        // page container already exists -> show the page
        // - either loaded by php page template, then we have to initialize the content first
        // - or already loaded before by ajax and is initialized

        const page = $(`#${pageID}`)
        page.hide()

        if (!page.hasClass("initialized")) {
          // page not yet initialized because it was loaded by php
          if (mobileView) closeNav(true, 1) // on init close nav menu fast

          if (page.hasClass("page-home")) {
            // is page home
            initHomePage()

            page.imagesLoaded(function () {
              // addLazyloadToElement(page);

              // console.log("page images loaded");
              window.scrollTo(0, 0)
              page.fadeIn()
            })
          } else {
            // all other pages

            page.imagesLoaded(function () {
              console.log("page images loaded")
              page.fadeIn()
              scrollTop()
            })
          }

          page.addClass("initialized")
        } else {
          if (mobileView) closeNav(true)
          // page content already initialized
          page.fadeIn()
          scrollTop()
        }
      }
    }
  }

  function scrollTop() {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      100
    )
  }

  function initHomePage() {
    console.log("init home page")

    // add handler for the view selectors
    $viewSelectorItems = $(".view-selector__item")
    $filterSelectorItems = $(".filter-selector__item") // top level cats -> people, places, etc
    $keywordsItems = $(".keywords__item")
    $projectsListingItems = $(".projects-listing__project-item")
    $keywordsContainer = $(".keywords-container") // contains the top level cats for filtering and the child cats as keywords
    $picturesContainer = $(".pictures-container") // contains all images from the posts featured image
    $projectsListingContainer = $(".projects-listing") // the list of project titles

    // add handler for the view selectors
    $viewSelectorItems.on("click", function (e) {
      if (mobileView) closeNav(true)
      openViewSelector($(this))
    })

    // top level categories ------> keywords filtering
    $filterSelectorItems.on("click", function (e) {
      e.preventDefault()
      const catID = $(this).data("cat-id")
      $(this).toggleClass("filter-selector__item--active")

      if ($(this).hasClass("filter-selector__item--active")) {
        $(`.keywords > a[data-parent-cat-id="${catID}"]`).addClass("keywords__item--visible")
      } else {
        $(`.keywords > a[data-parent-cat-id="${catID}"]`).removeClass("keywords__item--visible")
      }
    })

    // child categories .--------------------> click handler for keyword items:
    // -> show projects title which have this category assigned to
    $keywordsItems.on("click", function (e) {
      e.preventDefault()
      const catID = $(this).data("cat-id") // get category id from item data
      showProjectsListing(catID, $(this).text())

      // setTimeout(() => {
      //     // scroll to bottom
      //     const sh = $body.get(0).scrollHeight;
      //     console.log("body height", sh);
      //     // window.scrollTo(0, sh);
      //     $('html, body').animate({
      //         scrollTop: sh
      //     }, 400);

      // }, 200);
    })

    addPostLinksHandlers($projectsListingItems)

    // check which view is selected and show it
    const $selected = $(".view-selector__item--active")
    openViewSelector($selected)
  }

  function addLazyloadToElement($elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")
    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0,
    })

    // add load event listener for lazyload images
    $elem.find(`img.lazyload`).on("load", function () {
      // console.log("img on elem xx loaded", $elem.attr('id'));
      $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    })
  }

  $.fn.shuffleChildren = function () {
    $.each(this.get(), function (index, el) {
      var $el = $(el)
      var $find = $el.children()

      $find.sort(function () {
        return 0.5 - Math.random()
      })

      $el.empty()
      $find.appendTo($el)
    })
  }

  // view selector on home page --------------> keywords, pictures, projects

  function openViewSelector($selector) {
    if ($selector.length == 0) {
      // nothing selected -> random

      // check if a previous index is stored
      let prevIndex = localStorage.getItem("viewSelectorIndex")
      let nextIndex = 0
      if (prevIndex >= 0 && $viewSelectorItems.length > 1) {
        // prevIndex was stored in local storage (otherwise equal null) and more than 1 selector items
        prevIndex = Math.min($viewSelectorItems.length - 1, prevIndex) // ensure index is within upper limit if nbr of items changed

        do {
          nextIndex = Math.floor(Math.random() * $viewSelectorItems.length)
        } while (prevIndex == nextIndex)

        localStorage.setItem("viewSelectorIndex", nextIndex)
      } else {
        nextIndex = Math.floor(Math.random() * $viewSelectorItems.length)
      }

      $selector = $viewSelectorItems.eq(nextIndex)
    }

    const selector = $selector.data("selector")
    console.log("open view selector:", selector)

    $viewSelectorItems.removeClass("view-selector__item--active")

    if (selector === "keywords") {
      $selector.addClass("view-selector__item--active")
      $projectsListingContainer.hide()
      $picturesContainer.hide()

      $keywordsItems.css("opacity", "0") // hide all keywords
      $keywordsContainer.show()
      $keywordsItems.each(function (ind) {
        const el = $(this)
        setTimeout(function () {
          el.css("opacity", "") // clear opacity
          // if (el.hasClass('keywords__item--visible')) {
          //     console.log("keyword fade in");
          //     el.fadeIn();
          // }
        }, ind * 100)
      })
    } else if (selector === "pictures") {
      $selector.addClass("view-selector__item--active")
      $keywordsContainer.hide()
      $projectsListingContainer.hide()

      // show pictures container
      let $picLInks = $(".pictures-container__item")
      $picLInks.hide()
      $picturesContainer.shuffleChildren()
      addLazyloadToElement($picturesContainer) // reattach load event listener to pic items
      $picturesContainer.fadeIn()
      $picLInks = $(".pictures-container__item")
      addPostLinksHandlers($picLInks) // add / re-add click handler (shuffle loses handler)

      $picLInks.each(function (ind) {
        const el = $(this)
        setTimeout(function () {
          el.fadeIn()
        }, ind * 100)
      })
    } else if (selector === "projects") {
      $selector.addClass("view-selector__item--active")
      $keywordsContainer.hide()
      $picturesContainer.hide()
      showProjectsListing()
    }
  }

  function showProjectsListing(catID, catText) {
    // const sh = $body.get(0).scrollHeight - 5
    // window.scrollTo(0, sh)

    // filter projects according selected category or show all

    let elems
    if (catID) {
      // keyword clicked -> filter projects
      // const $visibleItems = $('.projects-listing__project-item:visible');
      // $visibleItems.css('visibility', 'hidden');
      // let nbrVisibleItems = $visibleItems.length;

      // console.log("nbr of visible items", nbrVisibleItems);

      $projectsListingItems.hide() // hide all project title items
      $projectsListingContainer.fadeIn() // show the listing container

      elems = $(`.projects-listing__project-item.cat-id-${catID}`)
      // const others = $(`.projects-listing__project-item`).not(`.cat-id-${catID}`);
      // elems = $projectsListingItems;

      elems.each(function (ind) {
        const el = $(this)
        setTimeout(function () {
          // if (nbrVisibleItems > 0) {
          //     $visibleItems.eq(nbrVisibleItems).hide();
          //     $visibleItems.eq(nbrVisibleItems).css('visibility', '');
          //     nbrVisibleItems -= 1;
          // }
          el.fadeIn(function () {
            // if last element
            if (ind == elems.length - 1) {
              // // clear all custom visibility
              // $visibleItems.css('visibility', '');
              // // hide all unselected items
              // others.hide();

              // last item -> scroll to last item
              elems.last()[0].scrollIntoView({
                behavior: "smooth",
              })
            }
          })
        }, ind * 100)
      })
    } else {
      // show all projects
      $projectsListingItems.hide() // hide all project title items
      $projectsListingContainer.fadeIn() // show the listing container

      elems = $projectsListingItems
      elems.each(function (ind) {
        const el = $(this)
        setTimeout(function () {
          el.fadeIn(function () {
            // // if last element
            // if (ind == elems.length - 1) {
            //     // hide the keywords container (top level categories and child categories) if not keywords view
            //     // if ($keywordsContainer.is(':visible') && $('.view-selector__item--active').data('selector') !== 'keywords') {
            //     //     $keywordsContainer.animate({
            //     //         height: 'toggle',
            //     //         opacity: 'toggle'
            //     //     }, 400);
            //     // }
            //     // scroll to last item
            //     elems.last()[0].scrollIntoView({
            //         behavior: 'smooth'
            //     });
            // }
          })
        }, ind * 100)
      })
    }

    // show the category title above the projects listing
    if (catText) $(".projects-listing__keyword").text(catText)
    else $(".projects-listing__keyword").text("")
  }

  function addPostLinksHandlers($els) {
    // console.log("add pic links handler", $els.length);

    $els.on("click", function (e) {
      e.preventDefault()
      // console.log(window.location);
      const ref = $(this).attr("href")
      console.log("open post", ref)

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        console.log("updated history ", ref)
        // update the title tag when menu click. page load set the title tag automatically (theme support 'title-tag')
        document.title = $(this).text() + " | " + blogName
      }
      const postID = $(this).data("post-id")
      if (postID) {
        openPostWithID(postID)
      }
    })
  }

  function openPostWithID(postID) {
    if (postID) {
      console.log("open post with id", postID)

      if (mobileView) closeNav(true, 1)

      // get permalink of the translated post and update the language switcher link
      $.getJSON(`${localized.root_url}/wp-json/translatedpostid/v1/get?id=${postID}&lang=${contentContainer.data("alt-language")}`, (post) => {
        // update the language switcher link
        $("li.lang-item > a").attr("href", post.href)
      })

      // hide all other page and post items
      $(".post-item-container").not(`#${postID}`).hide()

      currentContentID = postID

      if ($(`div#${postID}`).length == 0) {
        // post container does not exist yet -> load post with post id

        console.log("load post", postID)

        $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/" + postID, (post) => {
          console.log(post)

          postsContainer.append(`
                            <div id="${postID}" class="post-item-container post-type-post" data-title="${post.title.rendered}" data-history="${post.slug}">

                                <div class="post-item-title">                                    
                                    <div tabindex="0" class="post-item-title__title">
                                        <h1>${post.title.rendered}</h1>
                                        <div class="post-item-title__wrapper">
                                            <div class="post-item-title__close hamburg checked">
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-item-title__year">${post.year ? post.year : ""}</div>
                                    <div class="post-item-title__role">${post.role ? post.role : ""}</div>
                                </div>

                                <div class="post-item-content">
                                    ${post.content.rendered}
                                </div>
                            </div>
                        `)

          const newPost = postsContainer.children(`#${postID}`)

          if (newPost.find(".wp-swiper")) {
            window.dispatchEvent(new Event("DOMContentLoaded")) // to trigger swiper js block init
          }
          newPost.hide()
          newPost.imagesLoaded(function () {
            // addLazyloadToElement(newPost);
            newPost.fadeIn()
            scrollTop()
            // newPost.find('.post-item-title__title')[0].focus();
          })
        })
      } else {
        // post container already exists -> show the post
        console.log("post exists -> fade in")
        const post = $(`#${postID}`)
        post.hide()
        post.imagesLoaded(function () {
          // console.log("post images loaded");
          post.fadeIn()
          scrollTop()
          // post.find('.post-item-title__title')[0].focus();
        })
      }
    }
  }

  // $(window).on('load', function () {
  //     console.log("load event");
  // });

  // close post click handler
  $(document).on("click", ".post-item-title__close", function (e) {
    siteTitleLink.trigger("click")
  })

  openClose.on("click", function () {
    if (navOpen) {
      closeNav()
    } else {
      openNav()
    }
  })

  function openNav() {
    console.log("open nav")
    openClose.addClass("checked")
    // navContainer.addClass('site-header--visible');
    navChanging = true
    $(".site-header__menu").slideDown(() => {
      navChanging = false
    })
    navOpen = true
  }

  function closeNav(scroll, duration) {
    // console.log("close nav", navOpen, scroll, duration);

    if (typeof duration == "undefined") {
      duration = 400
    }
    openClose.removeClass("checked")
    // navContainer.removeClass('site-header--visible');

    if (navOpen) {
      navChanging = true
      // close nav menu if open
      $(".site-header__menu").slideUp(duration, () => {
        if (scroll) {
          $("html, body").animate(
            {
              scrollTop: 0,
              // scrollTop: navHeight
            },
            duration,
            function () {
              navOpen = false
              navChanging = false
            }
          )
        } else {
          navOpen = false
          navChanging = false
        }
      })
    } else {
      // nav menu already closed -> just scroll to top if requested
      if (scroll) {
        $("html, body").animate(
          {
            scrollTop: 0,
            // scrollTop: navHeight
          },
          duration,
          function () {
            navChanging = false
          }
        )
      } else {
        navChanging = false
      }
    }
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/" || pathname === "/it/") {
      // is home page
      console.log("is home")
      openPageLink(siteTitleLink)
    } else {
      // not home
      console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      $("li.menu-item:not(.lang-item)").each(function () {
        if ($(this).children("a").attr("href").includes(pathname)) {
          found = true
          console.log("menu link found", $(this).children("a").attr("href"))
          openPageLink($(this).children("a"))
          return false
        }
      })
      if (!found) {
        // was not a menu link
        console.log("not menu link")
        // check post item links next
        $(".projects-listing__project-item").each(function () {
          if (pathname === "/" + $(this).data("slug") + "/") {
            console.log("found post link")
            openPostWithID($(this).data("post-id"))
            found = true
            return false
          }
        })

        if (!found) {
          console.log("url is something else")
          // something other -> get post type for path
          $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, (post) => {
            console.log(post)
            if (post.ID > 0) {
              // post found
              if (post.post_type == "page") {
                // page that's not in the menu
                // hide all other page and post items
                $(".post-item-container").not(`#${pageID}`).hide()
                $("li.menu-item").removeClass("current-menu-item")
                $(`#${pageID}`).fadeIn()
              } else if (post.post_type == "post") {
                openPostWithID(post.ID)
              } else {
                // hide all other post items
                $(".post-item-container").not(`#${post.ID}`).hide()
                $("li.menu-item").removeClass("current-menu-item")
                $(`#${post.ID}`).fadeIn()
              }
            } else {
              // is not a post
            }
          })
        }
      }
    }
  }

  let resizeTimeout = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      navAndTitleHeight()
      checkMobileView()
      navAndTitleHeight()
    }, 400)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      if (mobileView) {
        // change from mobile to desktop
        openNav()
      }
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")

      // // ensure nav elements are visible -> clear styles from mobile view
      // navMenuWrapper.css('opacity', '');
      // navMenuWrapper.css('display', '');
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight() {
    navHeight = navContainer.outerHeight()
    $(":root").css("--navHeight", `${navHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01;
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  jQuery(document.body).on("post-load", function () {
    // New content has been added to the page.

    console.log("post-load")
  })

  document.addEventListener("scroll", function (e) {
    const currentScroll = window.pageYOffset
    // const currentScroll = window.scrollY;
    const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))

    // console.log("scroll: ", currentScroll, lastScroll, scrollDelta);
    // console.log("scroll: ", currentScroll, window.scrollY, lastScroll, scrollDelta, prevScrollDelta);

    prevScrollDelta = scrollDelta

    // // store scroll y for page contents
    if (!navOpen) {
      scrollYPositions[currentContentID] = currentScroll
    }

    // console.log(scrollYPositions);
    // if (window.scrollY === 0) {
    //     // on top
    //     // navContainer.removeClass('site-header--visible'); // -> sticky == -navheight
    //     openNav();
    // } else {
    if (currentScroll > lastScroll && scrollDelta > 5 && !navChanging) {
      // reduce sensitivity
      // down
      // navContainer.removeClass('site-header--visible'); // -> sticky == -navheight
      if (mobileView) closeNav()
      // console.log("down");
    }
    // else if (currentScroll < lastScroll && scrollDelta < -5 && window.scrollY > navHeight) {
    // up
    // openNav();
    // navContainer.addClass('site-header--visible'); // sticky = adminbarheight
    // console.log("up");
    // }
    // }

    lastScroll = Math.max(0, currentScroll)
  })

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }
})
